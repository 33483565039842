import { useState, useEffect, useRef } from 'react';
import axios from '../../../tools/axiosWrapper'
import _ from 'lodash';
import Magnifier from "react-magnifier";
import Tag from './tag.js'
import AddTo from '../addto/addto.js'
import AddTag from '../tags/addtag.js'
import ResizePanel from "react-resize-panel";
import ViewImage from './viewimage.js'
import TextAlert from '../textalert/textalert.js'
import anime from 'animejs/lib/anime.es.js';



import { ReactComponent as CommentSVG } from '../../../media/ui_symbols/comment.svg';
import { ReactComponent as DeleteSVG } from '../../../media/ui_symbols/delete.svg';
import { ReactComponent as MinusSVG } from '../../../media/ui_symbols/minus.svg';
import { ReactComponent as PlusSVG } from '../../../media/ui_symbols/plus.svg';
import { ReactComponent as PrivacySVG } from '../../../media/ui_symbols/privacy.svg';
import { ReactComponent as RotateSVG } from '../../../media/ui_symbols/rotate.svg';
import { ReactComponent as SaveSVG } from '../../../media/ui_symbols/save.svg';
import { ReactComponent as LeftArrow } from '../../../media/ui_symbols/leftarrow.svg';
import { ReactComponent as GraphicSVG } from '../../../media/ui_symbols/graphic.svg';
import { ReactComponent as ViewColSVG } from '../../../media/ui_symbols/view-col.svg';


const View = ({ _src, _list, _listtext, _now, _my, set_now }) => {

  const [zoom, setZoom] = useState(1);
  const [_tags, set_tags] = useState([]);


  const actionList= [
    {
      svg: <PrivacySVG />,
      titled: 'privacy alert',
      type: 'privacy',
      desc: 'this image contains sensitve information (names, emails, phone numbers)',
      left: 0
    },
    {
      svg: <CommentSVG />,
      titled: 'custom alert',
      type: 'custom',
      desc: 'custom comment',
      left: 0
    },
    {
      svg: <RotateSVG />,
      titled: 'rotation alert',
      type: 'rotation',
      desc: 'this image is not oriented correctly',
      left: 0
    },
    {
      svg: <GraphicSVG />,
      titled: 'graphic alert',
      type: 'graphic',
      desc: 'this image contains a diagram, graphic',
      left: 0
    }
  ]

  const [ panelView, setPanelView ] = useState(1)

  return (
    <>
      <section className='view'>
        <div className='view-head'>
          <div className='view-bar'>
            <AddTo _src={_src} id={_list[_now]} _my={_my} />
            <AddTag _tags={_tags} set_tags={set_tags} _list={_list} _now={_now} />
          </div>
        </div>

        <div className='view-main'
          style={{
            flexDirection: panelView ? 'column' : 'row'
          }}
          >
          <div
            className='view-controls'>
            <button
              className={'view-toggle'}
              style={{
              }}
              onMouseLeave={(e) => {
                anime({
                    targets: 'button.view-toggle',
                    easing: 'easeInQuad',
                    backgroundColor: 'rgba(255,255,255,0)',
                    duration: 200,
                    delay: 10,
                  })
              }}
              onMouseDown={(e) => {
                anime.timeline({
                    targets: 'button.view-toggle',
                    easing: 'easeOutQuad',
                  }).add({
                    backgroundColor: 'rgba(255,255,255,.5)',
                    duration: 200,
                    delay: 10,
                  })
                setPanelView(1 - panelView)
              }}
              onMouseUp={(e) => {
                anime.timeline({
                    targets: 'button.view-toggle',
                    easing: 'easeInQuad',
                  }).add({
                    backgroundColor: 'rgba(255,255,255,0)',
                    duration: 200,
                    delay: 10,
                  }).add({
                    keyframes: [
                      {border: '2px solid rgba(255,255,255,.8)'},
                      {border: '2px solid rgba(255,255,255,0)'},
                    ],
                    duration: 280,
                  }, '-=240')
              }}
              >
              <svg width={'20'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
                <g
                  style={{
                    fill:'none',
                    stroke: 'white',
                    strokeLinecap:'round',
                    strokeLineJoin: 'round',
                    strokeWidth:'8px'}}
                  >
                  <rect x="9" y="19" width="110" height="90" rx='10'/>
                  { !panelView
                    ? <line x1="119" y1="64" x2="9" y2="64"/>
                    : <line x1="64" y1="19" x2="64" y2="109"/>
                  }
                </g>
              </svg>
            </button>
          </div>
          <ResizePanel
            direction={panelView ? 's' : 'e'}
            style={panelView ? {height: '50%'} : {height: '100%'}}
            >
            <ViewImage _list={_list} _now={_now} panelView={panelView}/>
          </ResizePanel>

          <TextAlert _listtext={_listtext} _list={_list} _now={_now}/>
        </div>

      </section>
    </>

  );

}


export default View
