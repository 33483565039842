import { useEffect } from 'react'

import { useForm } from 'react-hook-form'
import axios from '../../../tools/axiosWrapper'
import anime from 'animejs/lib/anime.es.js'

const TextAlert = ({ _listtext, _list, _now }) => {
  const { register, handleSubmit, setValue } = useForm()

  const onSubmit = (data) => {
    const flash = anime
      .timeline({
        targets: 'button.textareasubmit',
        easing: 'easeInQuad',
        autoplay: false,
      })
      .add({
        backgroundColor: '#5C5C70',
        letterSpacing: '4.5px',
        fontWeight: '700',
        duration: 400,
      })
      .add({
        backgroundColor: '#34343F',
        letterSpacing: '3px',
        fontWeight: 500,
        duration: 300,
      })

    flash.play()

    try {
      axios.post('/tag/create', {
        tagalert: ['text', Date.now(), data],
        imageKey: _list[_now].id,
      })
    } catch {}
  }

  useEffect(() => {
    setValue('newtext', _listtext.length ? _listtext[_now].text : '')
  }, [_now, _listtext])

  return (
    <>
      <div className='view-text'>
        <form className={'textalert'}>
          <textarea type='text' {...register('newtext', { minLength: 2 })} />
        </form>

        <div></div>

        <div className={'textalert-controls'}>
          <button
            className='resetbutton'
            onClick={() =>
              setValue('newtext', _listtext.length ? _listtext[_now].text : '')
            }
          >
            {'reset'}
          </button>
          <button className='textareasubmit' onClick={handleSubmit(onSubmit)}>
            {'submit'}
          </button>
        </div>
      </div>
    </>
  )
}

export default TextAlert
