import { useState, useEffect, useMemo } from 'react';
import axios from '../tools/axiosWrapper';
import { useForm, useFieldArray } from 'react-hook-form';
import "./process.css";

const ACTION_TYPES = {
    "ocr": "Process OCR Tags",
    "text": "Process Text and Privacy Tags",
    "reset": "Reset Image to Unredacted Version"
};

const ProcessTool = () => {
    return (
        <div style={{padding: "32px"}}>
            <h1>FBArchive Image Tag Processor Tool</h1>
            <ActionForm actionKey="ocr" />
            <hr />
            <ActionForm actionKey="text" />
            <hr />
            <ActionForm actionKey="reset" />
        </div>
    );
};

const ActionForm = ({ actionKey = "reset" }) => {
    const { control, register, resetField, handleSubmit, formState: { errors } } = useForm();
    let actionName = ACTION_TYPES[actionKey] || "Action Name";

    const [ progress, setProgress ] = useState({});
    const [ isSubmitting, setSubmitting ] = useState(false);

    async function onSubmit(data) {
        let { passcode, imageIds } = data;
        let imageIdList = imageIds.trim().split("\n");
        let apiCalls = [];
        setProgress({});

        for (let id of imageIdList) {
            setProgress((p) => {
                return {
                    ...p,
                    [id]: { id, status: "processing", message: "" }
                };
            });

            const call = axios.post(`/img/process/${id}`, {
                action: actionKey,
                passcode
            })
            .then((response) => {
                const { data } = response;
                setProgress((p) => {
                    return {
                        ...p,
                        [id]: { id, status: "completed", message: data }
                    };
                });
            })
            .catch((error) => {
                const { data, status } = error.response;
                setProgress((p) => {
                    return {
                        ...p,
                        [id]: { id, status: "failed", message: data }
                    };
                });
            });

            apiCalls.push(call);
        }

        setSubmitting(true);
        await Promise.allSettled(apiCalls);
        setSubmitting(false);
    };

    const IdInputMultiple = () => {
        return (
            <>            
                <label>Image IDs (one per line)</label>
                <br />
                <textarea
                    rows={20}
                    cols={30}
                    {...register(`imageIds`, { required: true })}
                    disabled={isSubmitting}
                ></textarea>
                {errors['imageIds'] && <p>This field is required.</p>}
            </>
        );
    };

    const IdInputSingle = () => {
        return (
            <>
                <label>Image ID</label>
                <br />
                <input
                    type="text"
                    {...register(`imageIds`, { required: true })}
                    disabled={isSubmitting}
                />
                {errors['imageIds'] && <p>This field is required.</p>}
            </>
        );
    };

    const ProgressLog = () => {
        const ProgressItem = ({ item }) => {
            let emoji = "";
            if (item.status === "processing") {
                emoji = "⏱️";
            } else {
                emoji = (item.status === "completed" ? "✅" : "❌");
            }

            const MessageText = () => {
                if (!item.message) {
                    return <span></span>;
                }

                return <span>| {item.message}</span>
            }

            return (
                <>
                    <div>{ emoji } { item.id } <MessageText /></div>
                </>
            );
        };

        const items = Object.values(progress).map((p, i) => {
            return <ProgressItem key={`p${i}`} item={p} />;
        });
        
        return (
            <>
                <label>Progress:</label>
                <pre className='progress'>
                    {items}
                </pre>
            </>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3>{actionName}</h3>
            <div style={{ marginBottom: "8px" }}>
                <label>Passcode</label>
                <br />
                <input
                    type='password'
                    {...register('passcode', { required: true })}
                />
                <br />
                {errors.passcode && <p>This field is required.</p>}
            </div>

            <div style={{ marginBottom: "8px", display: "flex", gap: "8px" }}>
                <div>
                    {actionKey === "reset" ? <IdInputSingle /> : <IdInputMultiple />}
                </div>
                <div>
                    <ProgressLog />
                </div>
            </div>
            
            <div>
                <button type="Submit" disabled={isSubmitting}>{ isSubmitting ? "Processing..." : "Submit" }</button>
            </div>
        </form>
    );
};

export default ProcessTool;