import { useState, useEffect, useContext } from 'react'
import axios from '../../../tools/axiosWrapper'

import { ReactComponent as PlusSVG } from '../../../media/ui_symbols/plus.svg'
import { SlideContext } from '../slideContext.js'

import _ from 'lodash'
import { useForm } from 'react-hook-form'
import anime from 'animejs/lib/anime.es.js'
import './addtag.css'

const AddTag = ({ _tags, set_tags, _list, _now }) => {
  const { register, resetField, handleSubmit } = useForm()
  const onSubmit = (data) => {
    console.log(_list[_now].id)
    resetField('newtag')

    axios.post('/tag/create', {
      tagalert: ['custom', Date.now(), data],
      imageKey: _list[_now].id,
    })
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.target.blur()
    }
  }

  return (
    <form
      className={'addtag'}
      onBlur={handleSubmit(onSubmit)}
      onKeyDown={(e) => keyPress(e)}
    >
      <input
        type='text'
        {...register('newtag', { minLength: 1 })}
        placeholder={'custom alert [enter to submit]'}
      />
    </form>
  )
}

export default AddTag
