import { useState, useEffect, useContext } from 'react';
import axios from '../../../tools/axiosWrapper'

import './addto.css'
import { ReactComponent as PlusSVG } from '../../../media/ui_symbols/plus.svg';
import { ReactComponent as DownSVG } from '../../../media/ui_symbols/arrow.svg';
import { ReactComponent as MoreSVG } from '../../../media/ui_symbols/more.svg';

import { SlideContext } from '../slideContext.js'

import _ from 'lodash';
import { useForm } from "react-hook-form";
import anime from 'animejs/lib/anime.es.js';


const AddTo = ({_src, id, _my}) => {

  const current = useContext(SlideContext);

  const { register, handleSubmit, setValue, getValues } = useForm();

  const onSubmit = (data) => {
    var match = _my.find(i => i.documentid == data.docid)


    const flash = anime.timeline({
        targets: 'div.droptop input.title',
        easing: 'easeInQuad',
        autoplay: false,
      }).add({
        letterSpacing: '4px',
        fontWeight: '700',
        duration: 400,
      }).add({
        letterSpacing: '1.6px',
        fontWeight: 500,
        duration: 300,

      })

      flash.play()


      try {
        axios.post('/doc/update', {
          documentid: match.documentid,
          title: match.title,
          images: [...match.images, current.id],
          headings: [match.headings[0], match.headings[1], `updatetime=${Date.now()}`]
        })
      } catch {

      }


  }


  useEffect(() => {
    if(_my.length) {
      setValue('docid', _my[0].documentid)
      setValue('doctitle', _my[0].title)
    }
  },[_my])


  const [_dropped, set_dropped] = useState(0);


  const dropdown = () => {

    const up = anime({
        targets: 'div.dropdown > div',
        easing: 'easeInQuad',
        opacity: 1,
        duration: 200,
        autoplay: false,
      });

    const down = anime({
        targets: 'div.dropdown > div',
        // top: 0,
        easing: 'easeInQuad',
        opacity: 0,
        duration: 120,
        autoplay: false,
      });

    !_dropped ? up.play() : down.play()
    set_dropped(1 - _dropped)

  }


  const spread = () => {

    const up = anime.timeline({
        easing: 'easeInQuad',
        duration: 200,
        autoplay: false,
        delay: anime.stagger(50)
      }).add({
        targets: 'form.addto > svg > rect:nth-child(2)',
        x: 62,
      }).add({
        targets: 'form.addto > svg > rect:nth-child(3)',
        x: 107,
      })

    const down = anime.timeline({
        easing: 'easeInQuad',
        duration: 200,
        autoplay: false,
        delay: anime.stagger(50)

      }).add({
        targets: 'form.addto > svg > rect:nth-child(2)',
        x: 52,
      }).add({
        targets: 'form.addto > svg > rect:nth-child(3)',
        x: 87,
      })

    !_dropped ? up.play() : down.play()
    set_dropped(1 - _dropped)

  }




  return (
    <form className='addto' >

      <div className='addto'>

        <div className='addtobutton'  >
          <PlusSVG />
        </div>

        <div className='drop' onClick={handleSubmit(onSubmit)}>
          <div className='droptop' >
            <input className='id' {...register('docid')} />
            <input className='title' {...register('doctitle')} />
          </div>
        </div>
      </div>
      <MoreSVG onClick={() => dropdown()} onMouseEnter={() => spread()}  onMouseLeave={() => spread()}/>
      <div className='dropdown'>
        {_my.map(i => {
            return(
              <div
                onClick={() => {
                  setValue('docid', i.documentid)
                  setValue('doctitle', i.title)
                }}
                >
                {i.title}
              </div>
            )
          })
        }
      </div>


    </form>

  );
}


export default AddTo
